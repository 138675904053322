<template>
  <PageLayout v-loading="loading">
    <template #header>
      <h1 class="text-typography-primary mb-5">{{ translate('title') }}</h1>
    </template>

    <h3 class="text-typography-primary mb-4">{{ translate('subtitle') }}</h3>
    <ul class="list-group">
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.profilePicture') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <template v-if="profilePicture">
              <img ref="profile" :src="`${profilePicture}&token=${token}`" alt="" width="32" height="32" />
            </template>
            <template v-else>
              <img ref="profile" src="@/assets/images/avatar.png" alt="" width="32" height="32" />
            </template>
            <Button type="text" @click="settingsModalType = 'profilePicture'">
              {{ translate('table.actions.edit') }}
            </Button>
          </div>
        </div>
      </li>
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.name') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <p class="text-typography-primary">
              {{ userName }}
            </p>
            <Button type="text" @click="settingsModalType = 'name'">
              {{ translate('table.actions.edit') }}
            </Button>
          </div>
        </div>
      </li>
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.email') }}
            </p>
          </div>
          <div class="col d-flex align-items-center">
            <p class="text-typography-primary">
              {{ user?.username }}
            </p>
          </div>
        </div>
      </li>
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.cellphone') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <p class="text-typography-primary">
              {{ cellphone }}
            </p>
            <Button type="text" @click="settingsModalType = 'cellphone'">
              {{ translate('table.actions.edit') }}
            </Button>
          </div>
        </div>
      </li>
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.role') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <p v-if="isBookkeeperEmployee" class="text-typography-primary">
                {{ $t(`memberships.membershipTable.role.bookkeepingEmployee`) }}
              </p>
              <p v-else-if="isSupplierEmployee" class="text-typography-primary">
                {{ $t(`memberships.membershipTable.role.supplierEmployee`) }}
              </p>
              <p v-else-if="role" class="text-typography-primary">
                {{ $t(`memberships.membershipTable.role.${role}`) }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.locale') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <p v-if="locale === null">
                {{
                  translate('locale.systemDefault', 0, { systemDefault: translate(`locale.${systemDefaultLocale}`) })
                }}
              </p>
              <p v-else class="text-typography-primary">
                {{ translate(`locale.${locale}`) }}
              </p>
            </div>
            <Button type="text" @click="settingsModalType = 'locale'">
              {{ translate('table.actions.edit') }}
            </Button>
          </div>
        </div>
      </li>
    </ul>
    <SettingsModal
      v-if="settingsModalType"
      :type="settingsModalType"
      :user="user"
      :token="token"
      @close="settingsModalType = null"
      @update="handleUserUpdate"
      @removeProfilePicture="handleRemoveProfilePicture"
    />
  </PageLayout>
</template>

<script type="text/javascript">
import { computed } from 'vue';
import { Auth } from 'aws-amplify';

import { Button, PageLayout } from '@/modules/core';
import { useSendbirdToken } from '@/modules/chatModal';
import { USER_PROFILE_QUERY } from '@/modules/auth/utils/auth-queries';
import { useUser, useCurrentUserRole, useTenancy } from '@/modules/auth';
import { getDefaultSystemLocale } from '@/imports/startup/client/i18n';
import { ROLE_TYPE, BUSINESS_TYPE } from '@/modules/users/constants';

import SettingsModal, { LOCALE_OPTIONS } from './components/SettingsModal';

function generateInitialsAvatar(name) {
  const initials = name
    .split(' ')
    .map((str) => (str ? str[0].toUpperCase() : ''))
    .join('');
  const canvas = document.createElement('canvas');
  const radius = 30;
  const margin = 5;
  canvas.width = radius * 2 + margin * 2;
  canvas.height = radius * 2 + margin * 2;

  const ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fillStyle = 'grey';
  ctx.fill();
  ctx.fillStyle = 'white';
  ctx.font = 'bold 30px Arial';
  ctx.textAlign = 'center';
  ctx.fillText(initials, radius + 5, (radius * 4) / 3 + margin);
  return canvas.toDataURL();
}

export default {
  components: { PageLayout, SettingsModal, Button },
  setup() {
    const { sendbirdToken } = useSendbirdToken();
    const { user, token, refetch } = useUser();
    const { role } = useCurrentUserRole();
    const { currentTenant } = useTenancy();

    const isSupplierEmployee = computed(
      () => currentTenant.value?.type === BUSINESS_TYPE.SUPPLIER && role.value === ROLE_TYPE.EMPLOYEE
    );

    const isBookkeeperEmployee = computed(
      () => currentTenant.value?.type === BUSINESS_TYPE.BOOK_KEEPING && role.value === ROLE_TYPE.MANAGER
    );

    return {
      ROLE_TYPE,
      sendbirdToken,
      role,
      isSupplierEmployee,
      isBookkeeperEmployee,
      user,
      token,
      refetchUser: refetch,
    };
  },
  data() {
    return {
      settingsModalType: null,
      editNameDialog: false,
      editNameForm: {
        firstName: '',
        lastName: '',
      },
      editNameFormRules: {
        firstName: [{ required: true, message: this.$t('settings.enterFirstName'), trigger: 'change' }],
        lastName: [{ required: true, message: this.$t('settings.enterLastName'), trigger: 'change' }],
      },
      changeLanguageDialog: false,
      locales: [
        { locale: 'en', name: 'English' },
        { locale: 'he', name: 'עברית' },
      ],
      loading: false,
    };
  },
  computed: {
    userName() {
      if (!this.user?.profile) return;
      return `${this.user?.profile?.firstName} ${this.user?.profile?.lastName}`;
    },
    locale() {
      return this.user?.profile?.locale;
    },
    cellphone() {
      return this.user?.profile?.cellphone ? `0${this.user?.profile?.cellphone.replace('+972', '')}` : null;
    },
    profilePicture() {
      return this.user?.profile?.profilePicture;
    },
    systemDefaultLocale() {
      return getDefaultSystemLocale();
    },
  },
  methods: {
    async handleRemoveProfilePicture() {
      this.settingsModalType = null;
      this.loading = true;
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.deleteUserAttributes(user, ['picture']);
        await this.refetchUser();
        const initialsProfilePictureInBase64 = generateInitialsAvatar(this.userName);
        const file = await fetch(initialsProfilePictureInBase64)
          .then((res) => res.blob())
          .then((blob) => new File([blob], 'profileInitials.png'));
        await this.$sendbird.connect(this.user.id, this.sendbirdToken);
        await this.$sendbird.updateCurrentUserInfo({ nickname: this.userName, profileImage: file });
        await this.$sendbird.disconnect();
        this.$message.success(this.translate('messages.userUpdateSuccess'));
      } catch {
        this.$message.error(this.translate('messages.userUpdateFailed'));
      } finally {
        this.loading = false;
      }
    },
    async handleUserUpdate(data) {
      this.loading = true;
      const setObject = {};
      const settingsType = this.settingsModalType;
      this.settingsModalType = null;
      let nickname = this.userName;
      let profilePictureFile = null;

      switch (settingsType) {
        case 'name':
          setObject['given_name'] = data.firstName;
          setObject['family_name'] = data.lastName;
          nickname = `${data.firstName} ${data.lastName}`;
          break;
        case 'profilePicture':
          setObject['picture'] = data.url;
          profilePictureFile = new File([data.blob], 'profile.png');
          break;
        case 'cellphone':
          setObject['phone_number'] = `+972${data.replace('-', '').substring(1)}`;
          break;
        case 'locale':
          setObject[settingsType] = data;
          break;
        default:
          this.loading = false;
          return;
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        const { locale, ...rest } = setObject;
        const isSystemDefaultLocale = locale === LOCALE_OPTIONS.SYSTEM_DEFAULT;
        if (isSystemDefaultLocale) {
          await Auth.deleteUserAttributes(user, ['locale']);
        }
        if (Object.keys(rest).length > 0 || (!isSystemDefaultLocale && Object.keys(setObject).length > 0)) {
          const payloadToUpdate = isSystemDefaultLocale ? rest : setObject;
          await Auth.updateUserAttributes(user, payloadToUpdate);
        }
        await this.refetchUser();
        await this.$sendbird.connect(this.user.id, this.sendbirdToken);
        await this.$sendbird.updateCurrentUserInfo({ nickname, profileImage: profilePictureFile });
        await this.$sendbird.disconnect();
        this.$message.success(this.translate('messages.userUpdateSuccess'));
      } catch (err) {
        this.$message.error(this.translate('messages.userUpdateFailed'));
      } finally {
        this.loading = false;
      }
    },
    updateUserInCache(updateParams) {
      const apolloClient = this.$apollo.getClient();
      const { me: userInCache } = apolloClient.readQuery({ query: USER_PROFILE_QUERY });
      this.$nextTick(() => {
        apolloClient.writeQuery({
          query: USER_PROFILE_QUERY,
          data: {
            me: {
              ...userInCache,
              profile: {
                ...userInCache.profile,
                ...updateParams,
              },
            },
          },
        });
      });
    },
    translate(key, count, args = null) {
      return args ? this.$tc(`profileSettings.${key}`, count, args) : this.$t(`profileSettings.${key}`);
    },
  },
};
</script>

<style lang="scss" scoped>
li .row {
  height: 54px;
}
</style>
