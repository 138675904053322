import { gql } from '@apollo/client/core';

export const PROFILE_PICTURE_FILE_UPLOAD_PERMISSION = gql`
  query getFileUploadPermission($filename: String!) {
    getFileUploadPermission(filename: $filename) {
      filePath
      url
      fields
      photoUrl
    }
  }
`;
