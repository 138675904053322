import { render, staticRenderFns } from "./SettingsModal.vue?vue&type=template&id=08142503&scoped=true"
import script from "./SettingsModal.vue?vue&type=script&lang=js"
export * from "./SettingsModal.vue?vue&type=script&lang=js"
import style0 from "./SettingsModal.vue?vue&type=style&index=0&id=08142503&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08142503",
  null
  
)

export default component.exports